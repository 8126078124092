import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import useBrowserSize from '../../hooks/browser-size';

const Packages = (props) => {
  const showPackages = props.packages !== undefined && props.packages.length > 1;
  const packages = [];

  const [, screenSize] = useBrowserSize();
  const isDesktop = ['md', 'lg', 'xl'].includes(screenSize);

  if (showPackages) {
    packages.push(
      props.packages.map((p, idx) => (
        <>
          <Col key={idx} className={`${idx !== 0 && isDesktop && 'border-start'} border-grey`}>
            <ol className="list-unstyled">
              <li className="pb-2">{p.name}</li>
              <li className="pb-2">
                {p.price} {p.availability == 'Sold Out' && <span>Sold Out</span>}
              </li>

              {p.capacity > 0 && <li className="pb-2">Capacity: {p.capacity}</li>}
              {p.description.length > 0 && <li className="pb-2">{p.description}</li>}
            </ol>
          </Col>
        </>
      ))
    );
  }

  return (
    <>
      {props.packages !== undefined && props.packages.length > 1 && (
        <div className="mt-5">
          <h3 className="border-bottom border-grey py-2">Event Pricing</h3>
          <Row className="row-cols-md-3 row-cols-auto">{packages}</Row>
        </div>
      )}
    </>
  );
};

export default Packages;

Packages.propTypes = {
  packages: PropTypes.array.isRequired
};
