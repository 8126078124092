//import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import EventTile from './../components/EventTile/EventTile';
import NullTile from './../components/NullTile/NullTile';
import ExperienceTile from './../components/ExperienceTile/ExperienceTile';
import { getCurrentExperiences } from './../utilities/api';
import { GlobalContext } from './../contexts/globalContext';
import { WebIdDispatchContext } from '../contexts/navigationContext';
import { NagivationHighlightEnum } from '../enums/enums';
import { NavigationHighlightDispatchContext } from '../contexts/navigationContext';
import Hero from './../components/Hero/Hero';
import { Helmet } from 'react-helmet';

const UpcomingEventListingPage = () => {
  const [currentEvents, setCurrentEvents] = React.useState([]);
  const [firstPastEvent, setFirstPastEvent] = React.useState(undefined);
  const [eventContent, setEventContent] = useState(<></>);

  const [firstLoad, setFirstLoad] = useState(true);

  const globalData = useContext(GlobalContext);
  const setWebId = useContext(WebIdDispatchContext);
  const setNavigationHighlightState = useContext(NavigationHighlightDispatchContext);

  const setFilters = () => {
    if (
      globalData === undefined ||
      globalData.api.regions === undefined ||
      globalData.api.categories === undefined ||
      globalData.api.paymentTypes === undefined ||
      globalData.api.years === undefined ||
      globalData.api.months === undefined
    ) {
      return;
    }

    setWebId(globalData.api.homepage.webId);
  };

  useEffect(() => {
    setFilters();
  }, [globalData, setWebId]);

  useEffect(() => {
    setNavigationHighlightState(NagivationHighlightEnum.UPCOMING);
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
    setFilters(); // Explicit call here to handle navigating back to the page, because the global effect won't run again

    getCurrentExperiences()
      .then((eventRes) => eventRes.json())
      .then((eventResult) => {
        setCurrentEvents(eventResult.events);
        setFirstPastEvent(eventResult.firstPastEvent);
        setFirstLoad(false);
      });
  }, []);

  useEffect(() => {
    if (currentEvents.length === 0 && !firstLoad) {
      setEventContent(
        <Col md={{ span: 10, offset: 1 }} className="text-center">
          <p className="h5 font-clanot-med" aria-live="polite">
            Sorry, there are no upcoming events at this time. Please check back often as new events
            are added frequently.
          </p>
        </Col>
      );
      setWebId(globalData.api.homepage.webId);

      return;
    }

    setEventContent(currentEvents.map((e, idx) => <EventTile key={idx} {...e} />));
  }, [firstLoad, currentEvents, globalData.api.homepage]);

  return (
    <>
      <Helmet>
        <title>Upcoming Experiences | United Card Events from Chase</title>
        <meta
          name="description"
          content="United Card Events from Chase offers access to once-in-a-lifetime experiences exclusively for select Chase Cardmembers."
        />
      </Helmet>
      {globalData !== undefined && globalData.api.homepage !== undefined && (
        <Hero homepage={globalData.api.homepage} isPastEvents={false} />
      )}

      {/* Event Tiles */}

      <Container id="listing-page" fluid as="main" className="bg-beige pb-5 pt-3">
        <Row>
          <Col>
            <Container className="px-1">
              <Row className="gy-4 pt-3">
                <h2 className="d-lg-none h6 font-neue-plak-med mt-3">Upcoming Experiences</h2>
                <p aria-live="polite" className="visually-hidden">
                  {`There are currently ${
                    eventContent.length == undefined ? 'zero' : eventContent.length
                  } listed events`}
                </p>
                {eventContent}
                {firstPastEvent !== undefined && (
                  <NullTile
                    // Null should have image ONLY if it is only 1 tile wide ever
                    isImagePresent={currentEvents.length % 4 === 3 ? false : true}
                    imgSrc={firstPastEvent.tileImage.imageURL}
                  />
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      {/* Experience Tiles */}
      {
        <Container fluid as="section" className="bg-white" aria-label="Elevate Your Experience">
          <Container className="py-5 ">
            <Row>
              <h2 className="text-center h2 mb-5 font-neue-plak-med">Elevate your Experience</h2>
            </Row>
            <Row className="gy-4 row-cols-2 row-cols-lg-3">
              {globalData !== undefined &&
                globalData.api.evergreenTiles !== undefined &&
                globalData.api.evergreenTiles.map((val, idx) => (
                  <ExperienceTile key={idx} status="united-event" {...val} />
                ))}
            </Row>
          </Container>
        </Container>
      }
    </>
  );
};

export default UpcomingEventListingPage;

UpcomingEventListingPage.propTypes = {};
