import React, { useContext, useState } from 'react';
// import ButtonMain from './../../components/ButtonMain/ButtonMain';
import { ChaseLogo } from './../../components/Logos/Logos';
import { Facebook, Instagram, OffsiteLink } from './../../components/Icons/Icons';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ContentModal from './../../components/ContentModal/ContentModal';
import { GlobalContext } from './../../contexts/globalContext';
import { WebIdContext } from '../../contexts/navigationContext';
import pushDataLayer from '../../utilities/analytics';

const Footer = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const globalData = useContext(GlobalContext);
  const webId = useContext(WebIdContext);

  const dataFooter = {
    event: 'navigationClick',
    attributes: {
      location: 'footer'
    }
  };

  return (
    <>
      <Container fluid as="footer" className="border-top border-grey bg-white">
        <Container>
          <Row className="py-4">
            <Col
              md={6}
              className="d-flex flex-row align-items-center justify-content-center justify-content-md-start mb-4 mb-md-0">
              <p className="text-uppercase mb-0 me-3">Follow on</p>
              <Facebook
                className="mx-2"
                svgClass="fill-hover-purple"
                onClick={() =>
                  pushDataLayer({
                    event: 'socialMediaClick',
                    attributes: {
                      social_click: 'Facebook'
                    }
                  })
                }
              />
              <Instagram
                className="mx-2"
                svgClass="fill-hover-purple"
                onClick={() =>
                  pushDataLayer({
                    event: 'socialMediaClick',
                    attributes: {
                      social_click: 'Instagram'
                    }
                  })
                }
              />
            </Col>
            <Col md={6} className="text-md-end text-center">
              <ChaseLogo />
              <small className="d-block">
                <small>{webId}</small>
              </small>
            </Col>
            <Col lg={{ offset: 1, span: 10 }} className="pt-4 small">
              <ul className="d-flex flex-row flex-wrap justify-content-center list-unstyled">
                <li>
                  <NavLink
                    as="button"
                    bsPrefix="btn-link"
                    className="text-primary text-uppercase p-3 small border-0 bg-transparent font-neue-plak-med"
                    aria-label="Opens modal to learn more about United Card Events from Chase"
                    onClick={() => {
                      setShowAboutModal(true);
                      pushDataLayer(dataFooter);
                    }}>
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    as="button"
                    bsPrefix="btn-link"
                    className="text-primary text-uppercase p-3 small border-0 bg-transparent font-neue-plak-med"
                    aria-label="Opens modal to contact us"
                    onClick={() => {
                      setShowContactModal(true);
                      pushDataLayer(dataFooter);
                    }}>
                    Contact Us
                  </NavLink>
                </li>
                <li className="d-flex align-self-center">
                  <NavLink
                    bsPrefix="btn-link"
                    href="https://www.chase.com/index.jsp?pg_name=ccpmapp/privacy_security/protection/page/privacy-notice"
                    className="text-primary text-uppercase p-3 small font-neue-plak-med"
                    target="_blank"
                    aria-label="Privacy Policy: Opens in a new window."
                    onClick={() => pushDataLayer(dataFooter)}>
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    as="button"
                    bsPrefix="btn-link"
                    className="text-primary text-uppercase p-3 small border-0 bg-transparent font-neue-plak-med"
                    aria-label="Opens modal to frequently asked questions about United Card Events from Chase"
                    onClick={() => {
                      setShowFAQModal(true);
                      pushDataLayer(dataFooter);
                    }}>
                    Frequently Asked Questions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    as="button"
                    bsPrefix="btn-link"
                    className="text-primary text-uppercase p-3 small border-0 bg-transparent font-neue-plak-med"
                    onClick={() => {
                      setShowTermsModal(true);
                      pushDataLayer(dataFooter);
                    }}>
                    United Card Events Site Terms &amp; Conditions
                  </NavLink>
                </li>
                <li className="d-flex align-self-center">
                  <LinkContainer to="/sitemap">
                    <NavLink
                      bsPrefix="btn-link"
                      href="/sitemap"
                      className="text-primary text-uppercase p-3 small font-neue-plak-med"
                      onClick={() => pushDataLayer(dataFooter)}>
                      Sitemap
                    </NavLink>
                  </LinkContainer>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <p>
                <OffsiteLink />
                <small className="ps-1">You&apos;re now leaving Chase</small>
              </p>
              <p className="small line-height-200">
                Chase&apos;s website and/or mobile terms, privacy and security policies don&apos;t
                apply to the site or app you&apos;re about to visit. Please review its terms,
                privacy and security policies to see how they apply to you. Chase isn&apos;t
                responsible for (and doesn&apos;t provide) any products, services or content at this
                third-party site or app, except for products and services that explicitly carry the
                Chase name.
              </p>

              <p className="small text-uppercase font-neue-plak-med text-text-grey">
                &copy;{new Date().getFullYear()} JPMorgan Chase &amp; Co. All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      {globalData.api.contactModal !== undefined &&
        globalData.api.faqModal !== undefined &&
        globalData.api.termsModal !== undefined && (
          <>
            <ContentModal
              header={globalData.api.aboutModal.title}
              body={globalData.api.aboutModal.body}
              show={showAboutModal}
              onHide={() => setShowAboutModal(false)}
            />
            <ContentModal
              header={globalData.api.contactModal.title}
              body={globalData.api.contactModal.body}
              show={showContactModal}
              onHide={() => setShowContactModal(false)}
            />

            <ContentModal
              header={globalData.api.faqModal.title}
              body={globalData.api.faqModal.body}
              show={showFAQModal}
              onHide={() => setShowFAQModal(false)}
            />

            <ContentModal
              header={globalData.api.termsModal.title}
              body={globalData.api.termsModal.body}
              show={showTermsModal}
              onHide={() => setShowTermsModal(false)}
            />
          </>
        )}
    </>
  );
};

export default Footer;
